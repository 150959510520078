import styled from 'styled-components';

// Atoms
import { atMinWidth } from '../../styles/atoms/breakpoints';
import { font } from '../../styles/atoms/typography';

export const AccordionStyle = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 48px;
  width: 100%;

  ${atMinWidth.lg`
    gap: 64px
  `}
`;

export const AccordionList = styled.div`
  display: inherit;
  flex-flow: inherit;
  gap: 16px;
  max-height: 100%;
  transition: 0.3s;

  h3 {
    ${font('display', 'sm', '700')}

    ${atMinWidth.lg`
      ${font('display', 'md', '700')}
    `}
  }
`;
